import { Col, Container, Row } from "react-bootstrap";
import Img1 from "../../assets/images/alsunaidar.jpg";
import Img2 from "../../assets/images/arkanfood.jpg";
import Img3 from "../../assets/images/aman.jpg";
import Img4 from "../../assets/images/ykb.jpg";
const Fade = require("react-reveal/Fade");
function Block3() {
  return (
    <div className="type1-Block3">
      <Container>
        <h1 className="type1-txt4">Our Group of Companies</h1>
        <Fade right delay={1000} cascade>
          <div className="type1-line"></div>
        </Fade>
        <br />
        <p className="type1-txt5">
          We could not offer the range of services we provide today without the
          acquisitions, mergers and partnerships we have forged with other
          world-leading organisations. Here are some of them
        </p>
        <Row>
          <Col sm={3}>
            <Fade right delay={100} cascade>
              <div className="type1-Block3Box3 Rightline">
                <img src={Img1} className="type1-Block3Box3Image" />
              </div>
            </Fade>
          </Col>
          <Col sm={3}>
            <Fade right delay={200} cascade>
              <div className="type1-Block3Box3 Rightline">
                <img src={Img2} className="type1-Block3Box3Image" />
              </div>
            </Fade>
          </Col>
          <Col sm={3}>
            <Fade right delay={300} cascade>
              <div className="type1-Block3Box3 Rightline">
                <img src={Img3} className="type1-Block3Box3Image" />
              </div>
            </Fade>
          </Col>
          <Col sm={3}>
            <Fade right delay={400} cascade>
              <div className="type1-Block3Box3 Rightline">
                <img src={Img4} className="type1-Block3Box3Image" />
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Block3;
