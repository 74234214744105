import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { AiOutlineArrowDown } from "react-icons/ai";
const Fade = require("react-reveal/Fade");
function Block1() {
  const [index, setIndex] = useState(0);
  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    beforeChange: (oldIndex: any, newIndex: any) => {
      console.log(oldIndex, newIndex);
      setIndex(newIndex);
    },
  };
  return (
    <div className="type2-Block1">
      <video loop autoPlay={true} id="vid" muted>
        <source
          type="video/mp4"
          src={
            "https://bairuha-bucket.s3.ap-south-1.amazonaws.com/arkanmars-files/bg1.mp4"
          }
        />
      </video>
      <div className="type2-Block1overlay">
        <Container fluid>
          <Row>
            <Col sm={10}>
              <Fade bottom cascade>
                <div className="type2-Block1Box1">
                  <h2 className="type2-Block1txt1">WELCOME TO</h2>
                  <h1 className="type2-Block1txt2">ARKAN MARS</h1>
                  <br />
                  <h3>DMCC and HFZA Registered Company</h3>
                </div>
              </Fade>
            </Col>
            <Col sm={2}>
              <div className="type2-Block1Box2">
                <Fade bottom cascade>
                  <div style={{ flex: 1 }}>
                    <div className="type2-Block1txt3">HOME</div>
                    <div className="type2-Block1txt3">ABOUT GROUP</div>
                    <div className="type2-Block1txt3">DIVISIONS</div>
                  </div>
                </Fade>
                <Fade bottom>
                  <div className="type2-Block1Box2btn1">
                    <AiOutlineArrowDown />
                  </div>
                </Fade>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Block1;
