import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Type1 from "./type1";
import Type2 from "./type2";
import Type3 from "./type3";
import Type4 from "./type4";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route index element={<Type1 />} />;
        <Route path="/t1" element={<Type1 />} />;
        <Route path="/t2" element={<Type2 />} />;
        <Route path="/t3" element={<Type3 />} />;
        <Route path="/t4" element={<Type4 />} />;
      </Routes>
    </div>
  );
}

export default App;
