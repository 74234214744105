import { Col, Container, Row } from "react-bootstrap";
import Img1 from "../../assets/images/Aurgwan.png";
import Img2 from "../../assets/images/Caprari.jpg";
import Img3 from "../../assets/images/arkan.jpg";
import { BsArrowUpRight } from "react-icons/bs";
const Fade = require("react-reveal/Fade");
function Block4() {
  return (
    <div className="type1-Block4">
      <Container>
        <h1 className="type1-txt4">Our Divisions</h1>
        <Fade right delay={1000} cascade>
          <div className="type1-line"></div>
        </Fade>
        <br />
        <p className="type1-txt5">
          We could not offer the range of services we provide today without the
          acquisitions, mergers and partnerships we have forged with other
          world-leading organisations. Here are some of them
        </p>
        <br />
        <Row>
          <Col sm={6}>
            <Fade right delay={500} cascade>
              <div className="type1-Block4Item">
                <div>
                  <img src={Img1} className="type1-Block4Img" />
                </div>
                <div className="type1-Block4Item2">
                  <div className="type1-Block4txt1">Aurgwan Mobile</div>
                  <div style={{ flex: 1 }} />
                  <div className="type1-Block4txt2">
                    Know More <BsArrowUpRight />
                  </div>
                </div>
              </div>
            </Fade>
          </Col>
          <Col sm={6}>
            <Fade right delay={1000} cascade>
              <div className="type1-Block4Item">
                <div>
                  <img src={Img1} className="type1-Block4Img" />
                </div>
                <div className="type1-Block4Item2">
                  <div className="type1-Block4txt1">Aurgwan Telecom</div>
                  <div style={{ flex: 1 }} />
                  <div className="type1-Block4txt2">
                    Know More <BsArrowUpRight />
                  </div>
                </div>
              </div>
            </Fade>
          </Col>
          <Col sm={6}>
            <Fade right delay={500} cascade>
              <div className="type1-Block4Item">
                <div>
                  <img src={Img2} className="type1-Block4Img" />
                </div>
                <div className="type1-Block4Item2">
                  <div className="type1-Block4txt1">
                    Caprari Pumps Yemen Ltd
                  </div>
                  <div style={{ flex: 1 }} />
                  <div className="type1-Block4txt2">
                    Know More <BsArrowUpRight />
                  </div>
                </div>
              </div>
            </Fade>
          </Col>
          <Col sm={6}>
            <Fade right delay={1000} cascade>
              <div className="type1-Block4Item">
                <div>
                  <img src={Img3} className="type1-Block4Img" />
                </div>
                <div className="type1-Block4Item2">
                  <div className="type1-Block4txt1">
                    Arkan Mars Peroleum - HFZA
                  </div>
                  <div style={{ flex: 1 }} />
                  <div className="type1-Block4txt2">
                    Know More <BsArrowUpRight />
                  </div>
                </div>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Block4;
