import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Logo from "../../assets/images/arjuwanmainlogo-removebg-preview.png";
import Menu from "../../assets/images/menu.svg";

function Header() {
  const [isTop, setTop] = useState(true);
  const [menu, setMenu] = useState(false);
  const [contact, setcontact] = useState(false);
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const tsTop = window.scrollY < 100;
      if (tsTop !== isTop) {
        setTop(tsTop);
      }
    });
  });
  return (
    <div className={isTop ? "type1-Header" : "type1-Header scroll"}>
      <Container>
        <Row>
          <Col sm={4}>
            <div className="type1-headerBox1">
              <div>
                <img src={Menu} />
              </div>
              <div>MENU</div>
            </div>
          </Col>
          <Col sm={4}>
            <div className="type1-headerBox2">
              <img src={Logo} />
            </div>
          </Col>
          <Col sm={4}>
            <div className="type1-headerBox3">
              <button className="type1-headerButton">GET IN TOUCH</button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
