import "./styles.scss";
import Header from "./components/header";
import Footer from "./components/footer";

import Block1 from "./components/block1";
import Block2 from "./components/block2";
import Block3 from "./components/block3";
import Block4 from "./components/block4";
import Block5 from "./components/block5";
import Block6 from "./components/block6";

function Type1() {
  return (
    <div className="Type1">
      <Header />
      <Block1 />
      {/* <Block2 /> */}
      {/* <Block3 /> */}
      {/* <Block4 /> */}
      {/* <Footer /> */}
    </div>
  );
}

export default Type1;
