import { Col, Container, Row } from "react-bootstrap";
import {
  SlSocialFacebook,
  SlSocialTwitter,
  SlSocialLinkedin,
} from "react-icons/sl";
function Footer() {
  return (
    <div className="type1-Footer">
      <Container>
        <Row>
          <Col>
            <br /> <br /> <br />
            <div className="type1-FooterBox1">
              <div>
                <SlSocialFacebook />
              </div>
              <div>
                <SlSocialTwitter />
              </div>
              <div>
                <SlSocialLinkedin />
              </div>
            </div>
          </Col>
          <Col>
            <div className="type1-Footertxt1">Any Questions?</div>
            <div className="type1-Footertxt2">04-585 0978</div>
            <br />
            <div className="type1-Footertxt1">Send Email</div>
            <div className="type1-Footertxt2">operation@marspetroleum.biz</div>
          </Col>
          <Col>
            <div className="type1-Footertxt1">Our Office</div>
            <div className="type1-Footertxt2">
              Office 16-K , Level 16, Gold Tower Cluster I, Jumeriah Lakes
              Towers, Dubai, U.A.E
            </div>
            <br />
            <div className="type1-Footertxt1">Sunday to Thursday</div>
            <div className="type1-Footertxt2">9:00 Am - 6:00 Pm</div>
          </Col>
        </Row>
        <br />
      </Container>
      <div className="type1-Footertxt3">
        © 2022 Copyright: https://www.arkanmars.com
      </div>
    </div>
  );
}

export default Footer;
