import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SlArrowDown } from "react-icons/sl";
const Fade = require("react-reveal/Fade");
function Block1() {
  const [delay1, setDelay1] = useState(false);
  const [delay2, setDelay2] = useState(false);
  return (
    <div className="type1-Block1">
      <Fade bottom>
        <video loop autoPlay={true} id="vid" muted>
          <source
            type="video/mp4"
            src={
              "https://bairuha-bucket.s3.ap-south-1.amazonaws.com/arkanmars-files/bg2.mp4"
            }
          />
        </video>
      </Fade>

      <div className="type1-Block1overlay">
        <Container fluid>
          <Row>
            <Col sm={4} style={{ margin: 0, padding: 0 }}>
              <Fade bottom delay={100}>
                <div className="type1-box1">
                  <h1 className="type1-txt1">
                    Welcome to <br />{" "}
                    <span>
                      <span style={{ color: "red" }}>Arjuwan</span> Petroleum
                      DMCC
                    </span>
                  </h1>
                </div>
              </Fade>
            </Col>
            <Col sm={4} style={{ margin: 0, padding: 0 }}>
              <Fade bottom delay={500}>
                <div className="type1-box2">
                  <h2 className="type1-txt2">
                    EXPLORE MORE <SlArrowDown />
                  </h2>
                </div>
              </Fade>
            </Col>
            <Col sm={4} style={{ margin: 0, padding: 0 }}>
              <Fade bottom delay={500}>
                <div className="type1-box3">
                  <p className="type1-txt3">
                    We take pride in introducing our petroleum company, Arjuwan
                    Petroleum, dedicated to delivering high-quality petroleum
                    products and services. With a commitment to excellence and
                    sustainability, we serve clients with the utmost
                    professionalism and expertise.
                    <br />
                    Contact Us:
                    <br />
                    trading@arjuwanpetroleum.com
                  </p>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Block1;
