import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import {
  SlSocialFacebook,
  SlSocialTwitter,
  SlSocialLinkedin,
} from "react-icons/sl";
function Header() {
  return (
    <div className="Type2-Header">
      <Container fluid>
        <Row>
          <Col sm={3}>
            <div className="Type2-HeaderBox1">
              <img src={Logo} />
            </div>
          </Col>
          <Col sm={2}></Col>
          <Col sm={7}>
            <div className="Type2-HeaderBox3">
              <div className="Type2-HeaderBox2btn2">
                <SlSocialFacebook />
              </div>
              <div className="Type2-HeaderBox2btn2">
                <SlSocialTwitter />
              </div>
              <div className="Type2-HeaderBox2btn2">
                <SlSocialLinkedin />
              </div>
              <div className="Type2-HeaderBox2">
                <div className="Type2-HeaderBox2btn">CONTACT US</div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
