import { Container, Row, Col } from "react-bootstrap";
import type { CountryContext, Data } from "react-svg-worldmap";
import WorldMap from "react-svg-worldmap";
const Fade = require("react-reveal/Fade");
function Block2() {
  const data = [
    { country: "cn", value: 1 }, // china
    { country: "in", value: 2 }, // india
  ];

  const getStyle = ({
    countryValue,
    countryCode,
    minValue,
    maxValue,
    color,
  }: CountryContext) => ({
    fill: color,
    fillOpacity: countryValue
      ? 0.1 + (1.5 * (countryValue - minValue)) / (maxValue - minValue)
      : 0,
    stroke: "#000",
    strokeWidth: 2,
    strokeOpacity: 0.3,
    cursor: "pointer",
  });

  return (
    <div className="type1-Block2">
      <Container fluid>
        <Row>
          <Col sm={6}>
            <Fade left delay={800} duration={2000}>
              <div className="type1-Block2box1">
                <WorldMap
                  color="red"
                  title=""
                  value-suffix="people"
                  size="responsive"
                  data={data}
                  borderColor={"#000"}
                  styleFunction={getStyle}
                />
              </div>
            </Fade>
          </Col>
          <Col sm={5}>
            <Fade right cascade>
              <h1 className="type1-txt4">About Us</h1>
              <Fade right cascade>
                <div className="type1-line"></div>
              </Fade>
              <br />
              <p className="type1-txt5">
                We would proudly like to introduce our company registered in
                DMCC Dubai and Hamriya FZE, with our main office located in
                Sana, Yemen R.O Our Business roots are linked to the renowned Al
                Sonidar group, ( www.alsonidargroup.com ), our group details as
                furnished below We are the major shareholders of Yemen Kuwait (
                https://www.yk-bank.com ) (
                www.yk-bank.com/en/about/board-of-directors ).
              </p>
              <p className="type1-txt5">
                On top of that, we are an established and major supplier of
                goods/products and services to the International UN Agencies
                like WFP, UNOPS, UNHCR, IOM, NRC to their respective offices in
                Yemen. We presently transport 60,000 MT (and is expanding), of
                Gasoil and Gasoline to Yemen mainly from UAE on Time and Voyage
                Chartered Vessels.
              </p>
              <p className="type1-txt5">
                We have leased storage tanks with nearly 100,000 CBM capacity at
                Hamriyah Free Zone – Sharjah.
              </p>
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Block2;
